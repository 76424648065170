import React, { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import Lottie from 'lottie-react';
import styled from 'styled-components';

import { Wide } from '../../../components/styled';
import { Link } from '../../../components';
import cubesAnimation from '../assets/cubes_animation.json';
import { breakpoints } from '../../../components/styled/variables';

export default function NoJobs() {
  const { ref, inView } = useInView();
  const lottieRef = useRef(null);

  return (
    <Container width={1180}>
      <Text>
        <h2>
          Still searching? <br /> We’ll find a role that fits
        </h2>
        <p>
          If you can’t find the role you’re looking for, but you’re still
          interested in working at Fidel API, contact us at{' '}
          <Link to="mailto:careers@fidel.uk" target="_blank">
            careers@fidel.uk
          </Link>
          . We’ll be in touch when a new role is available that might be a good
          fit for you.
        </p>
      </Text>
      <Cubes ref={ref}>
        <Lottie
          ref={lottieRef}
          animationData={cubesAnimation}
          loop={false}
          autoplay={inView}
        />
      </Cubes>
    </Container>
  );
}

const Container = styled(Wide)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 20px;
  margin: 40px auto;
`;

const Text = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: ${breakpoints.tabletPortrait}) {
    width: 50%;
    padding-right: 10%;
    text-align: left;
    margin-bottom: 0;
  }
`;

const Cubes = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.tabletPortrait}) {
    width: 50%;
  }
`;
