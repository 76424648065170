import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Wide } from '../../../components/styled';
import BlueBlock from '../assets/blue_block.svg';
import GreenBlock from '../assets/green_block.svg';
import PurpleBlock from '../assets/purple_block.svg';
import { breakpoints } from '../../../components/styled/variables';

const message =
  'Help us build the world’s leading suite of financial infrastructure APIs';

export default function Banner() {
  const image = require('../assets/banner_bg.png').default;
  return (
    <Container width={1440}>
      <Message>
        <Block left={100} top={8}>
          <BlueBlock />
        </Block>
        <Block left={80} top={76}>
          <PurpleBlock />
        </Block>
        <Block left={4} top={16}>
          <GreenBlock />
        </Block>
      </Message>
      <ImgContainer>
        <img src={image} alt={message} />
      </ImgContainer>
    </Container>
  );
}

const Container = styled(Wide)`
  display: flex;
  width: 100%;
  margin-top: -160px;
  position: relative;

  @media (min-width: ${breakpoints.header}) {
    width: max-content;
  }
`;

const Message = styled.div`
  position: relative;
  width: 100%%;
  padding: 80px 20px;
  h1 {
    font-size: 2.5em;
    line-height: 1.25em;
  }

  @media (max-width: ${breakpoints.tablet}) {
    h1 {
      margin-top: 2.6em;
    }
  }

  @media (min-width: ${breakpoints.header}) {
    width: 30%;
    padding: 12% 0 12% 4%;
    h1 {
      font-size: 3em;
      line-height: 1.25em;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 12% 0 12% 10%;
    width: 45%;
  }
`;

const blockAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(16px);
  }
`;

const Block = styled.div<{ left: number; top: number }>`
  width: fit-content;
  position: absolute;
  z-index: 1;
  top: ${({ top }) => top}%;
  left: ${({ left }) => left}%;
  overflow: visible;
  width: 100%;
  animation: ease-in-out 3s infinite alternate ${blockAnimation};
`;

const ImgContainer = styled.div`
  width: 60%;
  max-height: 770px;
  position: relative;
  top: -10%;
  z-index: -1;
  display: none;

  @media (min-width: ${breakpoints.header}) {
    display: inherit;
    width: 60%;
  }
`;
