import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Wide } from '../../../components/styled';
import FlexibleHoursIllustration from '../assets/flexible_hours.svg';
import InsuranceIllustration from '../assets/health_insurance.svg';
import HolidaysTrackingIllustration from '../assets/holidays_tracking.svg';
import OffsiteIllustration from '../assets/off-site.svg';
import EducationalResourcesIllustration from '../assets/educational_resources.svg';

interface Benefit {
  title: string;
  description: ReactElement;
  illustration: ReactElement;
}

export default function Benefits() {
  const benefits: Benefit[] = [
    {
      title: 'Flexible hours',
      description: (
        <dd>
          Ideally, you arrive in the morning and go home in the evening, but a
          busy life can be unpredictable. We leave it to you to manage your
          time.
        </dd>
      ),
      illustration: <FlexibleHoursIllustration />,
    },
    {
      title: 'No holidays tracking',
      description: (
        <dd>
          For real. We understand you need some time off now and then. Maybe you
          want to visit one of our 20+ team members’ home countries?
        </dd>
      ),
      illustration: <HolidaysTrackingIllustration />,
    },
    {
      title: 'Health insurance',
      description: (
        <dd>
          Each Fidel API member receives a full Vitality insurance pack, giving
          you great health rewards whilst helping keep you in tip-top shape!
          It’s a win-win.
        </dd>
      ),
      illustration: <InsuranceIllustration />,
    },
    {
      title: 'Yearly off-site',
      description: (
        <dd>
          From Napoli to Sitges, as long as it’s sunny, we’re happy! We’re not
          sure yet where we’ll be next year, but we do know it’ll be awesome.
        </dd>
      ),
      illustration: <OffsiteIllustration />,
    },
    {
      title: 'Educational resources',
      description: (
        <dd>
          Everyday is a school day! We offer all Fidel API team members access
          to Udemy and Audible to grow themselves professionally across a range
          of disciplines.
        </dd>
      ),
      illustration: <EducationalResourcesIllustration />,
    },
  ];

  return (
    <MainContainer width={1180} data-testid="benefits">
      <Heading>
        <h2>Benefits</h2>
        <p>
          We think it’s important to show our team some love with a range of
          employee benefits that help them lead happy, healthy, autonomous and
          flexible working lives.
        </p>
      </Heading>
      <BenefitsConatiner>
        {benefits.map(benefit => (
          <SingleBenefit key={benefit.title}>
            <div>{benefit.illustration}</div>
            <h4>{benefit.title}</h4>
            <p>{benefit.description}</p>
          </SingleBenefit>
        ))}
      </BenefitsConatiner>
    </MainContainer>
  );
}

const MainContainer = styled(Wide)`
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

const Heading = styled.div`
  max-width: 700px;
  margin-bottom: 80px;

  p {
    margin-bottom: 0;
  }
`;

const BenefitsConatiner = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: baseline;
`;

const SingleBenefit = styled.div`
  margin: 0 40px 80px 40px;
  max-width: 260px;

  p {
    color: #4f5d65;
  }

  h4 {
    margin: 24px 0 16px 0;
  }
`;
