import React from 'react';
import styled from 'styled-components';

import ChatboxIcon from '../assets/chatbox.svg';
import { Wide } from '../../../components/styled';
import ScrollArrow, {
  Direction,
  useScroll,
} from '../../../components/scroll-arrow';
import { breakpoints, colours } from '../../../components/styled/variables';

const teamTestimonials: string[] = [
  '“The working environment is conducive to productivity, and the whole team are proactively engaged in collaborative success.”',

  '“We trust each-other, there’s no holiday tracking or clocking in and out, but we make sure we get things done and are available when someone needs us.”',

  '“Great autonomy, the perks are amazing, you are given the flexibility you need to achieve the best possible results from your projects.”',

  '“The interview process was great, the people were really welcoming and informative.“',

  '“Sky’s the limit - Massive agency for each role - Flat communications, with enough structure to make decisions - Team culture is strong - Immense room for role growth across functions“',

  '“The individuals and panels I interacted with over the course of the process, were very personable and made the process feel quite casual and was able to give me a feel of the company and team culture.“',

  '“Great colleagues, great product, chance to make a real difference in the world, no-nonsense approach, ambitious and smart leaders.“',
];

export default function Testimonials() {
  const { scroll, scrollRef } = useScroll({ scrollAmount: 480 });

  return (
    <Wide width={1440}>
      <TextContainer width={1180}>
        <Text>
          <h2>It’s all about the Team</h2>
          <p>
            We’re nothing without our people. Having a great culture is central
            to everyday life at Fidel API. We ensure everyone is heard and all
            feedback, good or bad, is anonymous. Don’t believe us? Check out
            some of our Glassdoor testimonials below.
          </p>
        </Text>
        <ArrowsContainer>
          {['left', 'right'].map(direction => (
            <ScrollArrow
              key={direction}
              direction={direction as Direction}
              onClick={() => scroll(direction)}
            />
          ))}
        </ArrowsContainer>
      </TextContainer>
      <CardsContainer ref={scrollRef}>
        {teamTestimonials.map((message, idx) => (
          <Card key={idx}>
            <ChatboxIcon />
            <Message>{message}</Message>
          </Card>
        ))}
        <div style={{ minWidth: '76px' }} />
      </CardsContainer>
    </Wide>
  );
}

const TextContainer = styled(Wide)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  padding: 0 20px;
  margin-bottom: 20px;
`;

const Text = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    max-width: 60%;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    background: transparent;
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 76px;
    align-items: unset;
  }
`;

const Card = styled.div`
  padding: 30px;
  background: ${colours.greyBg};
  min-width: 100%;
  margin-right: 25px;

  @media (min-width: ${breakpoints.tablet}) {
    min-width: 480px;
    padding: 40px;
  }
`;

const Message = styled.p`
  font-weight: 300;
  margin-bottom: 0;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 90px;
  }
`;

const ArrowsContainer = styled.div`
  max-width: 40%;
  min-width: 180px;
  display: none;

  @media (min-width: ${breakpoints.tablet}) {
    display: inherit;
  }
`;
