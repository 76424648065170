import React from 'react';

import { Layout } from '../../layout';

import Banner from './components/banner';
import Benefits from './components/benefits';
import Description from './components/description';
import NoJobs from './components/no-jobs';
import OurValues from './components/our-values';
import Photos from './components/photos';
import Testimonials from './components/testimonials';

export default function Careers() {
  return (
    <Layout title="Careers">
      <Banner />
      <Description />
      <Photos />
      <OurValues />
      <Testimonials />
      <Benefits />
      <NoJobs />
    </Layout>
  );
}
