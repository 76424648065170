import React from 'react';
import styled from 'styled-components';

import Photo, { PhotoProps } from './photo';
import { Wide } from '../../../components/styled';
import { breakpoints, colours } from '../../../components/styled/variables';

const topPhotos: PhotoProps[] = [
  {
    file: 'coders',
    caption: '🐞 A couple of coders bug-hunting at Fidel API HQ',
    style: {
      width: '65%',
      position: 'relative',
      top: '60px',
    },
  },
  {
    file: 'hoodie',
    caption: '👕 You’re never fully dressed without a Fidel API hoodie',
    style: {
      position: 'relative',
      right: '-10px',
      width: '35%',
    },
  },
  {
    file: 'toosie',
    caption:
      '🐶 Coffee break with everyone’s favourite Fidel API employee, Toosie',
    style: {
      position: 'relative',
      left: '40%',
      top: '90px',
      marginBottom: '90px',
      maxWidth: '40%',
    },
  },
];

type Value = { title: string; description: string };
const values: Value[] = [
  {
    title: 'Be humble',
    description:
      'We’re a team of learners. It’s more important for us to build the best product possible than to always be right. We listen to alternative opinions, let things go (even when we love them) and leave our egos at the door.',
  },
  {
    title: 'Be honest',
    description:
      'We’re open and honest in everything we do. We don’t hide behind language or tell half-truths. We don’t pass the buck or make excuses. We’re continuously building on our founding principles of trust and transparency; we know they’re essential to get us where we’re going.',
  },
  {
    title: 'Be adaptable',
    description:
      'We’re always listening — to our customers, to the market and to each other. We’re adaptable, agile, and open to trying new things. We never waver in our core mission, but we’re creative about how we get there.',
  },
  {
    title: 'Be encouraging',
    description:
      'We’re empowering the next generation of innovation and that means empowering the people behind them, too. We work together and we help each other out, because we want everyone to benefit from the ecosystem we’re building. ',
  },
  {
    title: 'Be a leader',
    description:
      'We believe that passion breeds the best results. We stand up for what we believe in, run with ideas, and see projects through to the very end. We own our work, and we take pride in it.',
  },
];

export default function OurValues() {
  return (
    <Container width={1440}>
      <Background width={1180}>
        <h2>Our values</h2>
        <Text>
          At Fidel API, we live by our values - they feed into every decision we
          make. Our relationships with our clients, partners and colleagues are
          what define us, and what keep us facing forward as we grow.
        </Text>
        <TopPhotosContainer>
          {topPhotos.map(props => (
            <Photo key={props.file} {...props} />
          ))}
        </TopPhotosContainer>
        <ValuesContainer>
          {values.map(({ title, description }) => (
            <Value key={title}>
              <h3 style={{ fontSize: '24px' }}>{title}</h3>
              <p>{description}</p>
            </Value>
          ))}
        </ValuesContainer>
        <Photo
          key="dev"
          file="dev"
          caption="😎 Dev lays out our game plan for the year ahead"
          style={{
            width: '70%',
            marginTop: '20px',
            left: '14%',
          }}
        />
      </Background>
    </Container>
  );
}

const Container = styled(Wide)`
  padding: 380px 20px 0 20px;
  margin: -360px 20px 60px;
  background: ${colours.greyBg};
  position: relative;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 380px 100px 0 100px;
    margin: -360px auto 60px;
  }
`;

const Background = styled(Wide)`
  padding-bottom: 60px;
  position: relative;

  @media (min-width: ${breakpoints.tablet}) {
  }
`;

const Text = styled.p`
  @media (min-width: ${breakpoints.largeMobile}) {
    max-width: 50%;
  }
`;

const TopPhotosContainer = styled.div`
  position: relative;
  margin-top: -6px;

  @media (min-width: ${breakpoints.largeMobile}) {
    margin-top: -120px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: -160px;
  }
`;

const ValuesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const Value = styled.div`
  margin-top: 45px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 0 20px;
    width: 44%;
  }
`;
