import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { CountryFlag } from '../../../components';
import { Wide } from '../../../components/styled';
import { breakpoints, colours } from '../../../components/styled/variables';

const dataTitles = ['Employees', 'Nationalities', 'Languages'];

function titlesWithValues(titles: string[], values: number[]) {
  return titles.map((title, idx) => ({
    title,
    value: values[idx],
  }));
}

export default function Description() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            company {
              employees {
                count
                nationalities
                languages
              }
              offices {
                countries {
                  name: country
                  code
                }
              }
            }
          }
        }
      }
    `,
  );

  const { countries } = site.siteMetadata.company.offices;
  const { count, nationalities, languages } =
    site.siteMetadata.company.employees;

  const data = titlesWithValues(dataTitles, [count, nationalities, languages]);

  return (
    <Container width={960}>
      <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
        <SingleData>
          <h5>Offices</h5>
          {countries.map(country => (
            <CountryContainer key={country.code}>
              <CountryFlag key={country.code} {...country} rounded />
            </CountryContainer>
          ))}
        </SingleData>
        {data.map(({ title, value }) => (
          <SingleData key={title}>
            <h5>{title}</h5>
            <p>{value}</p>
          </SingleData>
        ))}
      </div>
      <DescriptionContainer>
        <p style={{ marginTop: '0' }}>
          We’re a team of over eighty, all focused on a shared mission —
          providing developers with best-in-class tools to build more powerful,
          frictionless and innovative payments-led experiences - ultimately,
          benefitting the financial ecosystem for everyone. It’s our passion for
          technology, drive for change and ability to collaborate that ensures
          we serve developers around the globe as best we can.
        </p>
        <p>
          Between us, we speak over twenty-five languages and represent more
          than twenty different nationalities. It’s our diversity of background,
          thinking, talents and skills that allows us to build truly global
          products for the developers who are driving payments innovation
          forward.
        </p>
      </DescriptionContainer>
    </Container>
  );
}

const Container = styled(Wide)`
  display: flex;
  margin-top: 40px;
  padding: 0 20px;
  flex-flow: row wrap;

  @media (min-width: ${breakpoints.tabletPortrait}) {
    flex-flow: row;
    margin-top: 120px;
  }
`;

const SingleData = styled.div`
  border-left: 2px solid ${colours.border};
  margin-bottom: 40px;
  padding-left: 20px;
  min-width: 50%;

  h5 {
    margin: 0;
    font-weight: 700;
  }

  p {
    margin: 8px 0 0 0;
    font-size: 18px;
    font-weight: 700;
    color: ${colours.primary};
  }
`;

const DescriptionContainer = styled.div`
  font-size: 18px;

  @media (min-width: ${breakpoints.tabletPortrait}) {
    margin-left: 160px;
  }
`;

const CountryContainer = styled.div`
  max-width: 30px;
  display: inline-block;
  margin-right: 8px;
  margin-top: 8px;

  img {
    width: 100%;
  }
`;
