import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import Photo, { PhotoProps } from './photo';
import { Wide } from '../../../components/styled';

const carousel: PhotoProps[] = [
  { file: 'christmas', caption: '🎄 Snapshot of our christmas party!' },
  {
    file: 'assemble',
    caption: '🌴 Fidel API team - assemble!',
  },
  {
    file: 'toosie2',
    caption: '🐕 Our London HQ resident dog Toosie',
  },
  {
    file: 'beer',
    caption: '🍺 Well-earned, post-work beers in Lisbon',
  },
  {
    file: 'break',
    caption: '🍹 Taking a quick break',
  },
  {
    file: 'lockdown',
    caption: '💻 ️ Staying strong together during the Lockdown',
  },
  {
    file: 'birthday',
    caption: '',
  },
  {
    file: 'off-site',
    caption: '🏖️ Always excited for our yearly off-site',
  },
  {
    file: 'lunch',
    caption: '🥐 Enjoying the sun during our lunch',
  },
  {
    file: 'friday',
    caption: '🍜 Friday lunch with the team',
  },
];

export default function Photos() {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <Wide
      width={1440}
      style={{
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1,
        paddingBottom: '40px',
      }}
      data-testid="careers-photos"
    >
      <Container ref={containerRef} width={containerRef.current?.scrollWidth}>
        {carousel.map(props => (
          <Photo key={props.file} {...props} style={{ marginRight: '8px' }} />
        ))}
      </Container>
    </Wide>
  );
}

const containerAnimation = keyframes`
  0% {
    left: 0;
  }
  100% {
    left: calc(100% - 4000px);
  }
`;

const Container = styled.div<{ width: number | undefined }>`
  width: max-content;
  position: relative;
  display: flex;
  width: 4000px;
  animation: 45s linear 2s infinite alternate ${containerAnimation};
`;
