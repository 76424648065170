import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../../components/styled/variables';

export type PhotoProps = {
  caption: string;
  file: string;
  style?: React.CSSProperties;
};

export default function Photo({ file, caption, style }: PhotoProps) {
  const src = require(`../assets/${file}.png`).default;
  return (
    <div style={{ display: 'inline-block', position: 'relative', ...style }}>
      <Container>
        <img src={src} alt={caption} />
        {caption && <Caption>{caption}</Caption>}
      </Container>
    </div>
  );
}

const Caption = styled.div`
  position: absolute;
  opacity: 0;
  max-width: max-content;
  bottom: -8px;
  left: -10px;
  z-index: 1;
  background: white;
  border-radius: 20px;
  padding: 8px 16px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s ease;
  font-size: 13px;

  @media (min-width: ${breakpoints.desktop}) {
    padding: 8px 24px;
    font-size: 14px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;

  :hover {
    ${Caption} {
      opacity: 1;
    }
  }
`;
